import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavigationService } from 'src/app/core/services/navigation-service/side-navigation.service';
import { ButtonTypeEnum } from '../../../enums/buttonType.enum';
import { ApplicationInformationService } from '../../../services/application-information-service/application-information.service';
import { CurrentNavigationItemService } from '../../../services/navigation-service/current-navigation-item.service';
import { PlatformService } from '../../../services/platform-service/platform.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    imports: [CommonModule]
})
export class FooterComponent {
  constructor(
    protected sideNavigationService: SideNavigationService,
    protected appInfoService: ApplicationInformationService,
    protected currentNavigationItemService: CurrentNavigationItemService,
    protected platformService: PlatformService,
  ) {}

  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected year = new Date().getFullYear();
}
