import { computed, Injectable, Signal } from '@angular/core';
import { StartPageSearchResults } from '../../interfaces/start-page-search-results.interface';
import { AppInterface } from '../../interfaces/app.interface';
import { PlatformService } from '../platform-service/platform.service';
import { AppTitleCriteria } from '../apps-search-service/criterias/app-title-criteria';
import { SearchableAppInterface } from '../apps-search-service/searchable-app.interface';
import { AppNameCriteria } from '../apps-search-service/criterias/app-name-criteria';
import { AppDescriptionCriteria } from '../apps-search-service/criterias/app-description-criteria';
import { AppSubtitleCriteria } from '../apps-search-service/criterias/app-subtitle-criteria';
import { AppKeywordsCriteria } from '../apps-search-service/criterias/app-keywords-criteria';
import { AppsSearchService } from '../apps-search-service/apps-search.service';
import { CollectionFinderItemDTO } from '../../interfaces/collection.interface';
import { WidgetUIService } from 'src/app/pages/start-page/services/widget-ui.service';
import { SearchableCollectionInterface } from '../collections-search-service/searchable-collection.interface';
import { CollectionUIService } from 'src/app/pages/start-page/services/collection-ui.service';
import { CollectionTitleCriteria } from '../collections-search-service/criterias/collection-title-criteria';
import { CollectionSubtitleCriteria } from '../collections-search-service/criterias/collection-subtitle-criteria';
import { CollectionKeywordsCriteria } from '../collections-search-service/criterias/collection-keywords-criteria';
import { CollectionsSearchService } from '../collections-search-service/collections-search.service';
import { CollectionDescriptionCriteria } from '../collections-search-service/criterias/collection-description-criteria';
import { SearchCriteria } from 'src/app/core/interfaces/search-criteria.interface';

@Injectable({
  providedIn: 'root',
})
export class StartPageSearchService {
  private readonly appSearchCriteria: SearchCriteria[] = [
    new AppTitleCriteria(),
    new AppNameCriteria(),
    new AppDescriptionCriteria(),
    new AppSubtitleCriteria(),
    new AppKeywordsCriteria(),
  ];
  private readonly collectionSearchCriteria: SearchCriteria[] = [
    new CollectionTitleCriteria(),
    new CollectionSubtitleCriteria(),
    new CollectionKeywordsCriteria(),
    new CollectionDescriptionCriteria(),
  ];
  private readonly allAppsOfFollowedCollections =
    this.collectionUIService.allAppsOfFollowedCollections;
  private readonly allApps: Signal<AppInterface[]> = computed(() =>
    Array.from(
      new Set(
        this.widgetUIService.apps().concat(this.allAppsOfFollowedCollections()),
      ),
    ),
  );
  private readonly allCollectionFinders: Signal<CollectionFinderItemDTO[]> =
    computed(() => this.collectionUIService.collectionFinderList());

  constructor(
    private platformService: PlatformService,
    private appsSearchService: AppsSearchService,
    private collectionsSearchService: CollectionsSearchService,
    private widgetUIService: WidgetUIService,
    private collectionUIService: CollectionUIService,
  ) {}

  searchStartPage(_query: string): StartPageSearchResults {
    _query = _query.trim().toLocaleLowerCase();
    const searchableApps = this.getSearchableApps(this.allApps());
    const searchableCollections = this.getSearchableCollections(
      this.allCollectionFinders(),
    );
    return this.mapToSearchResults(
      this.appsSearchService.search(
        searchableApps,
        _query,
        this.appSearchCriteria,
      ),
      this.collectionsSearchService.search(
        searchableCollections,
        _query,
        this.collectionSearchCriteria,
      ),
    );
  }

  private getSearchableApps(_apps: AppInterface[]): SearchableAppInterface[] {
    return _apps.filter((_app) => {
      if (this.platformService.isMobile()) {
        return _app.isActive && _app.supportsMobile;
      } else {
        return _app.isActive;
      }
    }) as SearchableAppInterface[];
  }

  private getSearchableCollections(
    _collections: CollectionFinderItemDTO[],
  ): SearchableCollectionInterface[] {
    return _collections.filter((collection) => {
      return this.platformService.isMobile()
        ? collection.supportsMobile
        : collection.supportsDesktop;
    }) as SearchableCollectionInterface[];
  }

  private mapToSearchResults(
    _appsFound: AppInterface[],
    _collectionFound: CollectionFinderItemDTO[],
  ): StartPageSearchResults {
    const bookmarkResults: AppInterface[] = [];
    const startPageResults: AppInterface[] = [];
    const appFinderResults: AppInterface[] = [];
    const collectionFinderResults: CollectionFinderItemDTO[] = [];
    let mixFinderItems = [
      ...(_appsFound as SearchableAppInterface[]),
      ...(_collectionFound as SearchableCollectionInterface[]),
    ].sort((a, b) => a.matchScore - b.matchScore);

    // Process bookmarks and startPage apps
    const isStartpageApp = (foundApp: AppInterface) => {
      return (
        foundApp.inDashboard ||
        this.allAppsOfFollowedCollections().some(
          (collectionApp) => collectionApp.appId === foundApp.appId,
        )
      );
    };

    _appsFound.some((foundApp) => {
      if (foundApp.isBookmark) {
        bookmarkResults.push(foundApp);
      } else if (isStartpageApp(foundApp)) {
        startPageResults.push(foundApp);
      }
    });

    // Exclude Start Page apps from App Finder items
    mixFinderItems = mixFinderItems.filter(
      (mixItem) =>
        !startPageResults.some(
          (spItem) => spItem.appId === (mixItem as AppInterface).appId,
        ),
    );

    // Process mixFinderItems for appFinders and collectionFinders
    mixFinderItems.some((mixFinderItem) => {
      if ('appId' in mixFinderItem) {
        appFinderResults.push(mixFinderItem as AppInterface);
      } else {
        collectionFinderResults.push(mixFinderItem as CollectionFinderItemDTO);
      }
    });

    return {
      appFinder: appFinderResults.filter((_app) => !_app.isBookmark),
      collectionFinder: collectionFinderResults,
      startPage: startPageResults,
      bookmark: bookmarkResults,
    };
  }
}
