<section class="app-container">
  <div (click)="openAppURL()" class="left-part">
    <article>
      <bookmark-icon [bookmark]="bookmark"/>
    </article>
  </div>
  <div class="right-part">
    <div class="label">
      <div>
        <a (click)="openAppURL()" class="title line-clamp-2">
          {{ bookmark.appName }}
        </a>
      </div>
    </div>
  </div>
</section>
