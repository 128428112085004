import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Bookmark} from '../../interfaces/app.interface';
import {UrlService} from '../../services/url-service/url.service';
import {BookmarkIconComponent} from "./bookmark-icon/bookmark-icon.component";

@Component({
    selector: 'bookmark',
    imports: [CommonModule, BookmarkIconComponent],
    templateUrl: './bookmark.component.html',
    styleUrls: ['./bookmark.component.scss']
})
export class BookmarkComponent {
  @Input({required: true}) bookmark!: Bookmark;

  constructor(private urlService: UrlService) {
  }

  openAppURL(): void {
    this.urlService.openURL(this.bookmark.bookmarkURL);
  }
}
