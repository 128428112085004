import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SideNavigationService } from '../../services/navigation-service/side-navigation.service';
import { CurrentNavigationItemService } from '../../services/navigation-service/current-navigation-item.service';
import { ResetStartpagePopupComponent } from '../reset-startpage-popup/reset-startpage-popup.component';
import { PlatformService } from '../../services/platform-service/platform.service';
import { MainContainerComponent } from "./main-container/main-container.component";
import { NavigationHistoryService } from "../../services/navigation-service/navigation-history.service";

@Component({
    selector: 'app-navigation',
    imports: [
        CommonModule,
        HeaderComponent,
        FooterComponent,
        ResetStartpagePopupComponent,
        MainContainerComponent,
    ],
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  constructor(
    protected sideNavigationService: SideNavigationService,
    protected currentNavigationItemService: CurrentNavigationItemService,
    protected platformService: PlatformService,
    protected navigationHistoryService: NavigationHistoryService
  ) {
    this.navigationHistoryService.init();
  }
}
