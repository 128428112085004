import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { NavigationItem } from '../../../interfaces/navigation-items';
import { PlatformService } from '../../../services/platform-service/platform.service';
import { UserService } from 'src/app/core/services/user-service/user.service';
import { SideNavigationItemComponent } from './side-navigation-item/side-navigation-item.component';
import { CurrentNavigationItemService } from '../../../services/navigation-service/current-navigation-item.service';
import { SideNavigationService } from '../../../services/navigation-service/side-navigation.service';
import { Subject } from 'rxjs';
import { IdeClientComponent } from './ide-client/ide-client.component';
import { NavigationItemsService } from '../../../services/navigation-items-service/navigation-items.service';
import { AppFinderFilterService } from 'src/app/pages/app-finder-page/services/app-finder-filter-service/app-finder-filter.service';
import { StartPageSearchBarComponent } from '../../../../pages/start-page/components/start-page-search-bar/start-page-search-bar.component';

@Component({
  selector: 'app-header',
  imports: [
    CommonModule,
    RouterLink,
    SideNavigationItemComponent,
    IdeClientComponent,
    StartPageSearchBarComponent
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
  @Output() toggledSidenav = new EventEmitter<boolean>();


  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    protected platformService: PlatformService,
    protected userService: UserService,
    protected currentNavigationItemService: CurrentNavigationItemService,
    protected sideNavigationService: SideNavigationService,
    protected navigationItems: NavigationItemsService,
    protected finderFilterService: AppFinderFilterService
  ) {
    this.addRouterEvent();
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  private addRouterEvent(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentNavigationItemService.currentNavigationItem =
          this.getCurrentNavigationItemByPathName(
            this.router.routerState.snapshot.root.firstChild?.routeConfig
              ?.path ?? ''
          );
        if (this.platformService.isMobile()) {
          this.sideNavigationService.closeSideNav();
        }
      }
    });
  }

  private getCurrentNavigationItemByPathName(pathName: string): NavigationItem {
    const navItem = this.navigationItems
      .getNavigationItems()
      .find((item) => item.path === pathName);
    return navItem ?? this.navigationItems.getStartPage();
  }

  currentNavigationOnStartPage() {
    return (
      this.currentNavigationItemService.currentNavigationItem.name ===
      this.navigationItems.getStartPage().name
    );
  }

  toggleSideNav(): void {
    if (this.finderFilterService.filterOpened) {
      return;
    }
    this.sideNavigationService.toggleSideNav();
  }
}
