import { Component, Input } from '@angular/core';

@Component({
    selector: 'no-apps-found',
    imports: [],
    templateUrl: './no-apps-found.component.html',
    styleUrl: './no-apps-found.component.scss'
})
export class NoAppsFoundComponent {
  @Input({ required: true }) showMessage: boolean = false;
}
