{
  "name": "ngportal",
  "version": "4.3.0-dev01750",
  "scripts": {
    "bom": "cyclonedx-npm --output-format JSON --output-file bom.json",
    "build": "npm install && ng build --configuration production",
    "build_develop": "npm install && ng build --configuration develop",
    "build_quality": "npm install && ng build --configuration quality",
    "build_production": "npm install && ng build --configuration production",
    "ng": "ng",
    "local": "ng serve -c local",
    "quality": "ng serve -c quality",
    "develop": "ng serve -c develop",
    "start": "ng serve",
    "test": "ng test",
    "format": "prettier --write ./src",
    "format-check": "prettier --check ./src",
    "lint": "ng lint"
  },
  "dependencies": {
    "@angular/animations": "^19.2.0",
    "@angular/cdk": "^18.2.2",
    "@angular/common": "^19.2.0",
    "@angular/compiler": "^19.2.0",
    "@angular/core": "^19.2.0",
    "@angular/forms": "^19.2.0",
    "@angular/platform-browser": "^19.2.0",
    "@angular/platform-browser-dynamic": "^19.2.0",
    "@angular/router": "^19.2.0",
    "@bosch/frontend.kit-npm": "3.6.0",
    "bower": "^1.8.14",
    "bower-art-resolver": "^2.0.10",
    "grunt-cli": "^1.5.0",
    "gulp-cli": "^3.0.0",
    "increase-memory-limit": "^1.0.7",
    "karma-cli": "^2.0.0",
    "license-checker": "^25.0.1",
    "npm-auth": "^1.1.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.7.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.0",
    "@angular-eslint/builder": "19.1.0",
    "@angular-eslint/eslint-plugin": "19.1.0",
    "@angular-eslint/eslint-plugin-template": "19.1.0",
    "@angular-eslint/schematics": "19.1.0",
    "@angular-eslint/template-parser": "19.1.0",
    "@angular/cli": "^19.2.0",
    "@angular/compiler-cli": "^19.2.0",
    "@cyclonedx/cyclonedx-npm": "^1.19.3",
    "@types/jasmine": "~5.1.4",
    "@typescript-eslint/eslint-plugin": "8.2.0",
    "@typescript-eslint/parser": "8.2.0",
    "autoprefixer": "^10.4.20",
    "eslint": "^8.57.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.44",
    "prettier": "^3.3.1",
    "tailwindcss": "^3.4.10",
    "typescript": "^5.5.4"
  },
  "engines": {
    "node": "20.11.1"
  }
}
